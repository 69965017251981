import { FC } from 'react'
import { Helmet } from 'react-helmet'

export const PageSeoTitle: FC<{ content: string }> = ({ content }) => (
  <Helmet>
    <title>{content}</title>
    <meta property="og:title" content={content} />
  </Helmet>
)

export const PageSeoDescription: FC<{ content: string }> = ({ content }) => (
  <Helmet>
    <meta name="description" content={content} />
    <meta property="og:description" content={content} />
  </Helmet>
)
