import { Box, LinkList, LinkListHeading, ListItem } from '@vp/swan'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { Link, PageProps } from 'gatsby'
import { FC } from 'react'
import { PageContextType } from 'types/application.types'

const NotFoundPage: FC<PageProps<unknown, PageContextType>> = () => (
  <Box align="center" mt={10}>
    <PageSeoTitle content="Mailing services: Page not found" />
    <PageSeoDescription content="Requested page not found" />
    <LinkListHeading>Available pages</LinkListHeading>
    {/* TODO check the deleted skin="standard" */}
    <LinkList>
      <ListItem>
        <Link to="/msp/mailing-services">EN - Upsell</Link>
      </ListItem>
      <ListItem>
        <Link to="/es/msp/mailing-services">ES - Upsell</Link>
      </ListItem>
      <ListItem>
        <Link to="/msp/upload">EN - Upload</Link>
      </ListItem>
      <ListItem>
        <Link to="/es/msp/upload">ES - Upload</Link>
      </ListItem>
    </LinkList>
  </Box>
)
export default NotFoundPage
